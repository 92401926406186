#about
{
    background-color: #fff;
    overflow-x: hidden;
 
}
.abtcon
{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 20px;
    
}
