#expertise
{
    background-color: #fff;
    overflow-x: hidden;
   
    
}
.xpcon
{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 20px;
}

.xpcon > .row {
    height: 350px; /* Adjust the height as needed */
    overflow-y: auto; /* Add scroll if content exceeds the fixed height */
}
ul li{
    margin-left: 15px;
    padding: 5px;
    color: black;
}