#accomplishments
{
    /* background-color: #d7d2cb; */
    background-color: #fff;
    
}
.abtcon
{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 20px;
    
}

.trow
{
    border-bottom: 1px solid #aaa;
}
.logo-container {
    max-width: 150px; /* Adjust the maximum width based on the maximum number of logos */
    height: 160px; /* Adjust the height as needed */
    overflow-x: auto; /* Horizontal scrollbar if needed */
    white-space: nowrap; /* Prevent wrapping of logos */
    display: flex;
    align-items: center;
    border: 1px solid #ccc; /* Border */
    background-color: #f8f9fa; /* Background color */
    text-align: left; /* Align content to the left */
}

.logo-container img {
    max-width: 100px; /* Adjusted to maintain aspect ratio */
    max-height: 100px; /* Adjusted to maintain aspect ratio */
    display: flex;
    align-items: center;
    padding: 5px;
}


