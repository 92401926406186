.nv
{
    text-decoration: none;
    color: #444;
    margin-left: 50px;
    padding: 3px;
    transition: 0.4s linear;
    margin-top: 5px;
}

.nv-bn
{
    text-decoration: none;
    color: #000;
    margin-left: 50px;
    background-color: rgb(156, 246, 246);
    transition: 0.4s linear;
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 10px;
    margin-top: 5px;
}

.nv:hover
{
    text-decoration: none;
    color: #444;
    transform: scale(1.1);
}
.nav-bg
{
    background-color:#fff;
    width:100%;
    padding:5px;
}