.card-b
{
    background-color: #fff;
   border: 2px solid #777;
    padding: 10px;
    margin-top: 10px;
}

.b-img
{
    height: 200px;
}