body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body
{
  overflow-x: hidden;
}
.blue-color
{
  color: #61dafb;
}
.a-left
{
  text-align: left;
}

.white-color
{
  color: #fff;
}

.light-white-color1
{
  color: #fff8;
}
.light-white-color2
{
  color: #fff5;
}
.bold
{
  font-weight: bold;
}
.background-dark
{
  background-color: #282c34;
}
.padc
{
  padding-left: 10%;
}
h5{
  color: #aaa;
}
.mag-color{
  color: #fff;
}
.dark-color
{
  color: #000;
}
.dark-color2
{
  color: #5e4638;
}
.grey-color{
  color: #1c130c;
}
.pink-color
{
  color:#916f5d
}
.pl-25+
{
  padding-left: 25px;
}
.justify
{
  text-align: justify;
  text-justify: inter-word;
}