#footer
{
    padding: 0px;
    margin: 0px;
}
.foot1
{
    width: 100%;
    background-image:  linear-gradient(#303d4348,#a98e81);
    border-top: 5px solid #a98e81;
  
}
.foot2
{
    left: 0px;
    bottom: 0px;
    background-image:  linear-gradient(#303d4348,#a98e81);
    border-top: 5px solid #a98e81;
    overflow-x: hidden;
    max-width: 100vw;
}
.fcard
{
    padding: 15px;
   background-color: #fff;

    position: relative;
    top: -30px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
}
.fcardb
{
    padding: 15px;
   background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
    width: 90%;
}

.fcard1
{
  
    padding: 15px;
    
    

}

.fcard2
{
    padding: 15px;
   
    

}